import { EmiData, SideBarProps } from './interface';

export const hiddeApps: string[] = ['bhim', 'web', 'default'];
export const apiStatus: any[] = [
    '300',
    '299',
    '125',
    '225',
    '120',
    300,
    299,
    125,
    225,
    120,
];
export const TRANSACTION_FILTERS = [
    'biller_category',
    'payment_mode',
    'status',
    'start_date',
    'end_date',
];
export const APIS = {
    INITIATE_TRANSACTION_API: '/initiate_transaction',
    CHARGEBACK_API: '/chargeback',
    RAISE_COMPLAINT_API: '/payment/raise_complaint',
    TRANSACTION_DETAILS: '/transaction_details',
    SETTLEMENT_DETAILS: '/settlement_details',
    FEEDBACK_FORM: '/feedback/form',
    FETCH_BBPS_BILL_API: '/fetch_bbps_bill',
    ADD_USER_DETAILS_API: '/payment-user/add_identity',
    UPDATE_USER_DETAILS_API: '/payment-user/update_identity',
    GET_FINAL_PAYABLE_AMOUNT: '/get_final_payable_amount',
    CREATE_USER_API: '/payment-user/signup',
    LOGIN_API: '/payment-user/login',
    PAYMENT_USER_AUTO_LOGIN: '/payment-user/auto_login',
    AUTO_LOGIN: '/auto_login',
    USER_PROFILE: '/payment-user/profile',
    LINKED_LOANS: '/linked_loans',
    TRANSACTION_STATUS: '/transaction_status',
    TRANSACTIONS_LIST: '/recent_transaction',
    GET_FILTERS_LIST: '/recent_transaction_meta_data',
    SUBSCRIPTION_API: '/public/subscription_status',
    BILLER_BY_CATEGORY: '/public/biller_by_category',
    BILLER_INFORMATION: '/public/biller_information',
    VALIDATE_VPA: '/validate-vpa',
    VPA_UPI_HANDLE: '/vpa',
    GET_MIN_MAX: '/get_min_max_amount',
    COMPLAINT_STATUS: '/complaint_status',
    RAISE_COMPLAINT: '/raise_complaint_meta_data',
    LOGOUT_USER: '/payment-user/logout',
    FEEDBACK_RESPONSE_API: '/feedback/response',
    EASY_SETTLE_BID_API: '/easy_settle_bid',
    TRANSACTION_INVOICE_API: '/public/transaction_invoice',
};
export const FormFields = [
    {
        label: 'Loan Account ID',
        key: 'loan_id',
        type: 'text',
        readOnly: true,
    },
    {
        label: 'Lender Name',
        key: 'company_name',
        type: 'text',
        readOnly: true,
    },
    {
        label: 'Your Full Name',
        key: 'applicant_name',
        type: 'text',
        readOnly: true,
    },
    { type: 'settlement_breakup', key: 'settlement' },
    {
        label: 'Reason',
        key: 'settlement_reason',
        type: 'select',
        options: [],
    },
    {
        label: 'Settlement Start Date',
        type: 'date',
        key: 'settlement_start_date',
    },
    {
        label: 'No of monthly instalment(s)',
        key: 'total_installment_count',
        type: 'number',
    },
    { type: 'emi_breakup', key: 'emi' },
];
export const generateEmiData = (
    date: string,
    count: number,
    countall: number
): EmiData[] => {
    if (date && count) {
        const startDateObj = new Date(date);
        let emis = [];
        for (let i = 0; i < +count; i++) {
            let emiDate = new Date(startDateObj);
            emiDate.setMonth(emiDate?.getMonth() + i * 1);
            emis.push({
                id: i + 1,
                amount: parseFloat((countall / +count)?.toString()).toFixed(2),
                date: emiDate?.toISOString().slice(0, 10),
            });
        }
        return emis;
    }
};
//helper function for formatting the first name and last name of user
export const formateName = (userData: any) => {
    let { fname = '', lname = '' } = userData;
    if (fname?.length + lname?.length <= 15) return `${fname} ${lname}`;
    else return `${`${fname} ${lname}`?.slice(0, 15)}...`;
};

export const SideBarMenuItems: SideBarProps = {
    home: {
        label: 'Home',
        url: '/home',
        disabled: false,
    },
    transaction: {
        label: 'Orders & Transactions',
        url: '/v2/transactionHistory?bckUrl=true&goBack=true',
        disabled: true,
    },
    complaint: {
        label: 'Complaint History',
        url: '/v2/complaints?bckUrl=true&goBack=true',
        disabled: true,
    },
    chargeback: {
        label: 'Disputes and Chargebacks',
        url: '/v2/chargeback?bckUrl=true&goBack=true',
        disabled: true,
    },
    profile: {
        label: 'Profile Settings',
        url: '/v2/profileSetting?bckUrl=true&goBack=true',
        disabled: true,
    },
};

export const ComplaintFields: { name: string; label: string }[] = [
    { name: 'complaint_type', label: 'Complaint Type' },
    { name: 'disposition', label: 'Disposition' },
];
export const ComplaintDetailsFields: { name: string; label: string }[] = [
    { label: 'Complaint Date', name: 'created' },
    {
        label: 'Complaint Type',
        name: 'complaint_type',
    },
    { label: 'Order ID', name: 'order_id' },
    { label: 'Disposition', name: 'disposition' },
    { label: 'Description', name: 'description' },
];
export const ChargebackDetailsFields: { name: string; label: string }[] = [
    { label: 'Dispute ID', name: 'dispute_id' },
    { label: 'Order ID', name: 'order_id' },
    { label: 'Loan ID', name: 'loan_id' },
    { label: 'Dispute Amount', name: 'dispute_amount' },
    { label: 'Last Updated Date', name: 'last_updated_date' },
    { label: 'Dispute Remark', name: 'dispute_remark' },
];
export const ChargebackTransactionFields: { name: string; label: string }[] = [
    { label: 'Status', name: 'dispute_status' },
    { label: 'Date', name: 'dispute_raised_date' },
    { label: 'Dispute ID', name: 'dispute_id' },
    { label: 'Last Updated At', name: 'last_updated_date' },
    { label: 'Dispute Remark', name: 'dispute_remark' },
];
