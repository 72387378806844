import React, { useCallback } from 'react';
import { Button } from 'antd';
import styles from './button.module.scss';
import _ from 'lodash';
import { ButtonShape } from 'antd/lib/button';

const CustomButton = ({
    text,
    click,
    disabled = false,
    className,
    loading = false,
    style = {},
    shape = 'round',
}: {
    text: string;
    click: Function;
    disabled?: boolean;
    className?: any;
    loading?: boolean;
    style?: React.CSSProperties;
    shape?: ButtonShape;
}) => {
    const throttlePress = useCallback(
        _.throttle(
            () => {
                click();
            },
            3000,
            { trailing: false, leading: true }
        ),
        [click]
    );

    return (
        <Button
            type="primary"
            onClick={() => throttlePress()}
            disabled={disabled}
            shape={shape}
            className={className || styles?.smallButton}
            loading={loading ?? false}
            style={style}
        >
            {text}
        </Button>
    );
};

export default CustomButton;
