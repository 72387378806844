import React from 'react'
import styles from './hamburger.module.css'
import GetIcon from './getIcon'

const hamburger = ({isUserLoggedIn, displaySideDrawer}) => {
    return (
        <div className={styles.hamburgerWrapper} onClick={() => displaySideDrawer()}>
            <GetIcon isLoggedIn={isUserLoggedIn}/>
        </div>
    )
}

export default hamburger