import axios from 'axios';
import {
    getHeaders,
    getCatchBlock,
    getSuccessNotification,
} from './apiServiceHelpers';

export const get = async ({
    endPoint = '',
    payload = {},
    useLoginApiBase = false,
    sendAuthHeader = false,
    cb = () => {},
    showNotification = false,
    backupToken = null,
    enc_order_id = null,
    retainUser = false, //check for logging out user or not
    version = 'v1', // for checking which version of api to use
}) => {
    try {
        let obj = await getHeaders(
            'get',
            endPoint,
            sendAuthHeader,
            'json',
            payload,
            {},
            useLoginApiBase,
            backupToken,
            enc_order_id,
            retainUser,
            version
        );
        const response = await axios(obj);
        if (response?.data?.success) {
            if (showNotification) getSuccessNotification(response?.data);
            return response?.data?.data;
        } else {
            cb && cb(response);
            throw { ...response?.data?.message };
        }
    } catch (error) {
        cb && cb(error);
        getCatchBlock(error);
    }
};

export const post = async ({
    endPoint = '',
    payload = {},
    responseType = 'json',
    useLoginApiBase = false,
    sendAuthHeader = false,
    backupToken = null,
    enc_order_id = null,
    retainUser = false, //check for logging out user or not,
    version = 'v1', // for checking which version of api to use
}) => {
    try {
        let obj = await getHeaders(
            'post',
            endPoint,
            sendAuthHeader,
            responseType,
            {},
            payload,
            useLoginApiBase,
            backupToken,
            enc_order_id,
            retainUser,
            version
        );
        const response = await axios(obj);
        if (response?.data?.success) {
            if (endPoint?.includes('easy_settle_bid'))
                return response?.data?.data;
            getSuccessNotification(response?.data);
            return response?.data?.data;
        } else throw { ...response?.data?.message };
    } catch (error) {
        getCatchBlock(error);
    }
};

export const postWithHtmlRedirect = async ({
    endPoint = '',
    payload = {},
    useLoginApiBase = false,
    backupToken = null,
    enc_order_id = null,
    retainUser = false, //check for logging out user or not
    version = 'v1', // for checking which version of api to use
}) => {
    try {
        let obj = await getHeaders(
            'postWithHtml',
            endPoint,
            true,
            '',
            {},
            payload,
            useLoginApiBase,
            backupToken,
            enc_order_id,
            retainUser,
            version
        );
        const response = await axios(obj);
        if (response?.data?.success) {
            getSuccessNotification(response?.data);
            return response?.data?.data;
        } else throw { ...response?.data?.message };
    } catch (error) {
        getCatchBlock(error);
    }
};

export const patch = async ({
    endPoint = '',
    payload = {},
    responseType = 'json',
    useLoginApiBase = false,
    backupToken = null,
    enc_order_id = null,
    retainUser = false, //check for logging out user or not
    version = 'v1', // for checking which version of api to use
}) => {
    try {
        let obj = await getHeaders(
            'patch',
            endPoint,
            true,
            responseType,
            {},
            payload,
            useLoginApiBase,
            backupToken,
            enc_order_id,
            retainUser,
            version
        );

        const response = await axios(obj);
        if (response?.data?.success) {
            getSuccessNotification(response?.data);
            return response?.data?.data;
        } else throw { ...response?.data?.message };
    } catch (error) {
        getCatchBlock(error);
    }
};
