import mixpanel from 'mixpanel-browser';

// Enabling the debug mode flag is useful during implementation,
// but it's recommended you remove it for production
export const MixPanelEvents = {
    billzy_home: 'Billzy Home',
    billzy_home_logged_in: 'Billzy Home Logged In',
    billzy_order_transaction: 'Billzy Order Transaction',
    billzy_profile_settings: 'Billzy Profile Settings',
    billzy_complaint_history: 'Billzy Complaint History',
    billzy_chargeback_history: 'Billzy Chargeback History',
    billzy_electricity: 'Billzy Electricity',
    billzy_payment_mode: 'Billzy Payment Mode',
    billzy_deeplink_mode: 'Billzy DeepLink Journey',
};

export const MixPanelCTA = {
    click: 'On Click',
    onLoad: 'On Load',
};

export const EventProperites = {
    userProfile: 'User Profile',
    loginOtp: 'Login Otp',
    resendOtp: 'Resend Otp',
    createAccount: 'Create Account',
    signUp: 'Sign up',
    logInPop: ' Log In Pop',
    logOutPop: 'Log Out Pop',
    loginAndCheck: 'Login and Check',
    loanRepaymentIcon: 'Loan Repayment Icon',
    rechargeAndBill: 'Recharge and Bill',
    login: 'Login',
    userTab: 'User Tab',
    addEmail: 'Add Email',
    additionalBar: 'Additional Bar',
    recentTranscation: 'Order and Transcation',
    transcationDetail: 'Transcation Detail',
    paymentInformation: 'Payment Information',
    complaintHistory: 'Complaint History',
    homePage: 'Home Page',
    add: 'Add',
    update: 'Update',
    save: 'Save',
    raiseComplaint: 'Raise Complaint',
    logOut: 'Log Out',
    billDetails: 'Bill Details',
    vpaVerification: 'UPI_ID_Verification',
    billzy_deeplink_mode: 'Deep_Link_landing',
};

export const handleLoginEvent = (property) => {
    mixpanel.reset();
    mixpanel.identify(property);
    mixpanel.register({ 'Mobile Number': property });
};

export const handlEvents = ({
    events = '',
    module = '',
    element = undefined,
    cta = '',
    para1 = {},
    para2 = {},
    mobileNumber = '',
    dataSource = undefined,
}) => {
    if (process.env.NODE_ENV === 'production') {
        mixpanel.init(process.env.NEXT_PUBLIC_MIX_PANEL_ID);
        if (mobileNumber) handleLoginEvent(mobileNumber);
        else if (
            events === MixPanelEvents.billzy_profile_settings &&
            element === EventProperites.logOut
        )
            mixpanel.reset();
        const property = {
            Module: module,
            Elements: element,
            Cta: cta,
            'Parameters 1': para1,
            'Parameters 2': para2,
            DataSource: dataSource,
        };
        mixpanel.track(events, property);
    }
};
