import { configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';
import paymentsReducer from '../slice/paymentsSlice';

// creating store
export const store = configureStore({
    reducer: {
        payments: paymentsReducer,
    },
});
// assigning store to next wrapper
const makeStore = () => store;

export const wrapper = createWrapper(makeStore);
