import React from 'react';

const getIcon = ({ isLoggedIn }) => {
    return !isLoggedIn ? (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M16.7347 14.6133C16.3136 13.6159 15.7026 12.7099 14.9356 11.9459C14.1709 11.1796 13.2651 10.5687 12.2682 10.1468C12.2593 10.1423 12.2503 10.1401 12.2414 10.1356C13.632 9.13114 14.536 7.49498 14.536 5.649C14.536 2.59096 12.0584 0.113281 9.00033 0.113281C5.9423 0.113281 3.46462 2.59096 3.46462 5.649C3.46462 7.49498 4.36864 9.13114 5.75926 10.1378C5.75033 10.1423 5.74141 10.1445 5.73248 10.149C4.73248 10.5709 3.83516 11.1758 3.06507 11.9481C2.29879 12.7128 1.68784 13.6186 1.26596 14.6155C0.8515 15.5915 0.627973 16.6379 0.607478 17.6981C0.606882 17.7219 0.611061 17.7456 0.619767 17.7678C0.628474 17.79 0.641533 17.8102 0.658175 17.8273C0.674817 17.8443 0.694704 17.8579 0.716666 17.8671C0.738627 17.8764 0.762218 17.8811 0.786049 17.8811H2.12533C2.22355 17.8811 2.30167 17.803 2.30391 17.707C2.34855 15.9838 3.04051 14.37 4.26373 13.1468C5.52935 11.8811 7.21016 11.1847 9.00033 11.1847C10.7905 11.1847 12.4713 11.8811 13.7369 13.1468C14.9602 14.37 15.6521 15.9838 15.6968 17.707C15.699 17.8052 15.7771 17.8811 15.8753 17.8811H17.2146C17.2385 17.8811 17.262 17.8764 17.284 17.8671C17.306 17.8579 17.3259 17.8443 17.3425 17.8273C17.3591 17.8102 17.3722 17.79 17.3809 17.7678C17.3896 17.7456 17.3938 17.7219 17.3932 17.6981C17.3709 16.6311 17.1499 15.5932 16.7347 14.6133ZM9.00033 9.48828C7.97578 9.48828 7.0115 9.08873 6.28605 8.36328C5.5606 7.63784 5.16105 6.67355 5.16105 5.649C5.16105 4.62444 5.5606 3.66016 6.28605 2.93471C7.0115 2.20926 7.97578 1.80971 9.00033 1.80971C10.0249 1.80971 10.9892 2.20926 11.7146 2.93471C12.4401 3.66016 12.8396 4.62444 12.8396 5.649C12.8396 6.67355 12.4401 7.63784 11.7146 8.36328C10.9892 9.08873 10.0249 9.48828 9.00033 9.48828Z"
                fill="url(#paint0_radial_3236_6807)"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_3236_6807"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(6.43886 -3.21819) rotate(83.0781) scale(21.2542 23.1513)"
                >
                    <stop stopColor="#5278C7" />
                    <stop offset="1" stopColor="#233F78" />
                </radialGradient>
            </defs>
        </svg>
    ) : (
        <svg
            width="18"
            height="16"
            viewBox="0 0 18 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M17.7489 0.138672H0.248884C0.15067 0.138672 0.0703125 0.219029 0.0703125 0.317243V1.74581C0.0703125 1.84403 0.15067 1.92439 0.248884 1.92439H17.7489C17.8471 1.92439 17.9275 1.84403 17.9275 1.74581V0.317243C17.9275 0.219029 17.8471 0.138672 17.7489 0.138672ZM17.7489 14.0672H0.248884C0.15067 14.0672 0.0703125 14.1476 0.0703125 14.2458V15.6744C0.0703125 15.7726 0.15067 15.853 0.248884 15.853H17.7489C17.8471 15.853 17.9275 15.7726 17.9275 15.6744V14.2458C17.9275 14.1476 17.8471 14.0672 17.7489 14.0672ZM17.7489 7.10296H0.248884C0.15067 7.10296 0.0703125 7.18332 0.0703125 7.28153V8.7101C0.0703125 8.80832 0.15067 8.88867 0.248884 8.88867H17.7489C17.8471 8.88867 17.9275 8.80832 17.9275 8.7101V7.28153C17.9275 7.18332 17.8471 7.10296 17.7489 7.10296Z"
                fill="url(#paint0_radial_3236_2610)"
            />
            <defs>
                <radialGradient
                    id="paint0_radial_3236_2610"
                    cx="0"
                    cy="0"
                    r="1"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="translate(6.27393 -2.80776) rotate(81.692) scale(18.8586 24.5494)"
                >
                    <stop stopColor="#5278C7" />
                    <stop offset="1" stopColor="#233F78" />
                </radialGradient>
            </defs>
        </svg>
    );
};

export default getIcon;
