import { createSlice } from '@reduxjs/toolkit';

const billzy_logo = '/billzy_logo.png';

export const paymentsSlice = createSlice({
    name: 'paymentsApp',
    initialState: {
        title: '',
        userEmail: '',
        userPhone: '',
        userObj: {},
        companyLogo: billzy_logo,
        showBillzyLogo: false,
        status: {},
    },
    reducers: {
        modifyTitle: (state, action) => {
            state.title = action.payload;
        },
        updateEmail: (state, action) => {
            state.userEmail = action.payload;
        },
        updatePhone: (state, action) => {
            state.userPhone = action.payload;
        },
        updateUserObj: (state, action) => {
            state.userObj = action.payload;
        },
        updateCompanyLogo: (state, action) => {
            state.companyLogo = action.payload;
        },
        updateShowBillzyLogo: (state, action) => {
            state.showBillzyLogo = action.payload;
        },
        updateStatus: (state, action) => {
            state.status = action.payload;
        },
    },
});

export const {
    modifyTitle,
    updateEmail,
    updatePhone,
    updateFirstName,
    updateLastName,
    updateUserObj,
    updateCompanyLogo,
    updateShowBillzyLogo,
    updateStatus,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;
